exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacypolicy-js": () => import("./../../../src/pages/en/privacypolicy.js" /* webpackChunkName: "component---src-pages-en-privacypolicy-js" */),
  "component---src-pages-en-products-index-js": () => import("./../../../src/pages/en/products/index.js" /* webpackChunkName: "component---src-pages-en-products-index-js" */),
  "component---src-pages-en-termsofuse-js": () => import("./../../../src/pages/en/termsofuse.js" /* webpackChunkName: "component---src-pages-en-termsofuse-js" */),
  "component---src-pages-gizlilikpolitikasi-js": () => import("./../../../src/pages/gizlilikpolitikasi.js" /* webpackChunkName: "component---src-pages-gizlilikpolitikasi-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kullanimkosullari-js": () => import("./../../../src/pages/kullanimkosullari.js" /* webpackChunkName: "component---src-pages-kullanimkosullari-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

